import React from 'react';
import { Routes, Route, Link } from "react-router-dom";
import './App.scss';

import Layout from './pages/Layout';
import Home from './pages/Home';
import Sorteio from './pages/Sorteio';

function App() {

	return (
    <div>
      <Routes>
        <Route path='/' element={<Layout/>}>
          <Route index element={<Home/>} />
          <Route path='sorteio' element={<Sorteio/>} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
      
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default App;
