export const listEvents = ['drawsBall','newGame'];
export class Events {
    name;
    socket;
    value;

    constructor(name, socket, value=null){
        this.name = name;
        this.socket = socket;
        if(value) this.value = value;
    }

    setValue(value){
        this.value = value;
    }

    dispatch(){
        this.socket.emit(this.name, this.value, (response)=>console.log(response));
    }
}