import React from 'react';
import {Outlet } from "react-router-dom";
import './../App.scss';

export default function Layout(){
    return (
        <div>
            <Outlet />
        </div>
    )
}
