import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignal } from '@fortawesome/free-solid-svg-icons';
import './../App.scss';
import socketIO from 'socket.io-client';
import { DrawsBall } from './../events/drawsBall';

export default function Sorteio(){
	const [bolasSortedas, setBolasSorteadas] = useState([]);
  const [novosorteio, setNovoSorteio] = useState(false);
  const [ultimaBola, setUltimaBola] = useState("");
  const [showUltimaBola, setShowultimaBola] = useState(false);
  const [config] = useState({
    numMin:1,
    numMax:75
  });
	const [connIO,setConnIO] = useState(null);
  const [ioConnected,setIoConnected] = useState(false);
  const bolas = new Array(config.numMax).fill(0).map((e,i)=>(i+1));
  const drawsBall = new DrawsBall(connIO);

  const bolasEmLinhas = _ => {
    const bolasLinhas = [];
    let linhaAtual = 0;
    for(const index in bolas){
      if(index  > 0 && index % 10 === 0){
        linhaAtual++;
      }

      if (Array.isArray(bolasLinhas[linhaAtual])){
        bolasLinhas[linhaAtual].push(bolas[index]);
      }else {
        bolasLinhas[linhaAtual] = [bolas[index]];
      }

    }
    return bolasLinhas;
  }

  const renderBolasTable = _ => {
    const arrLinhas = bolasEmLinhas();
    return (
      <>
        <div className="break"></div>
        <table cellPadding="2" cellSpacing="0">

          <thead >
            <tr>
              <th></th>
              <th>1</th>
              <th>2</th>
              <th>3</th>
              <th>4</th>
              <th>5</th>
              <th>6</th>
              <th>7</th>
              <th>8</th>
              <th>9</th>
              <th>0</th>
            </tr>
          </thead>
          <tbody>
            {arrLinhas.map((linha, index)=>(
              <tr key={index} className={index%2 ===0?"even":""}>
                <th>{index }</th>
                {linha.map((nBola, indexBola)=>(
                  <td key={indexBola}><div className={bolasSortedas.includes(nBola) ? "bola sorteada" : "bola"}>{nBola}</div></td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
   
  }

  const sortear = () => {
    const numero = randomInt(config.numMin,config.numMax);
    if(bolasSortedas.includes(numero)){
      return sortear();
    } else {

      const _bolasSortedas = [...bolasSortedas];
      _bolasSortedas.push(numero);
      setBolasSorteadas(_bolasSortedas);
      setUltimaBola(numero);
      
      setShowultimaBola(true);
      
      setNovoSorteio(true);
      setTimeout(()=>{
        setNovoSorteio(false);
        setTimeout(()=>setShowultimaBola(false),500)
      },1000);

      drawsBall.setValue(numero);
      drawsBall.dispatch();
      
      return numero;
    }
  }

  const novojogo = () => {
    if(window.confirm('Tem certeza?')){
      setBolasSorteadas([]);
      setUltimaBola("");
      setNovoSorteio(false);
    }
  }

  const printSorteadas = () => {
    const qtd = 5;
    const indice = (bolasSortedas.length > qtd)? bolasSortedas.length-qtd : 0;
    const end = (bolasSortedas.length < qtd) ? qtd : bolasSortedas.length + qtd;
    return bolasSortedas.length > 0 ? bolasSortedas.slice(indice,end).join(' - ') : "...";
  }

  const randomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	const connectServer = () =>{
    try{
      
      const socket = socketIO.connect('http://localhost:4000');
      socket.on('connect', function() {
        setIoConnected(true);
        console.log('conectou');
      });
      socket.on('disconnect', function() {
        setIoConnected(false);
        console.log('desconectou');
      });
      return socket;
    } catch (e){
      console.log(e);
    }
	}
	
	useEffect(()=>{
		const conn = connectServer();
    setConnIO(conn);
	},[]);
  

  useEffect(()=>{
		console.log(ioConnected, 'ioConnected');
		// conn.on('connect', data => {
		// 	console.log(data, 'connect');
		// })
	},[ioConnected]);
    return (
      <div className="App">
        <div className="titulo">Bingo Sorteio Eletronico 
      <span style={{color:ioConnected?'green':'red', marginLeft:15}}>
      <FontAwesomeIcon icon={faSignal} />
      </span>
      </div>
      <div className="break"></div>
      <div className="bolas">
        {/* {renderBolas()} */}
        {renderBolasTable()}
      </div>
      <div className="break"></div>
      <div className='infos'>
        <div className='iteminfo'>
          Útilmas sorteadas
          <div className="ultimos">
            {printSorteadas()}
          </div>
        </div>
        <div className='iteminfo'>
          Qtd
          <div className="ultimos">
            {bolasSortedas.length}
          </div>
        </div>
      </div>
      
      <div className="break"></div>
      <div className="botoes">
        <button onClick={()=>sortear()}>Sortear</button>
        <button className="azul" onClick={()=>novojogo()}>Novo Jogo</button>
      </div>
      {showUltimaBola &&
      <div className={novosorteio ? "bolaSorteada" : "bolaSorteada sumir"}>
        <div>{ultimaBola}</div>
      </div>
      }
        </div>
    )
}